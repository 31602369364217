import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// function getReCaptcha() {
//     grecaptcha.ready(function() {
//     grecaptcha.execute('6LcQhMMUAAAAAN8mNHD4PWVgslWkFw_fFUht0cjo', {action: 'homepage'}).then(function(token) {
//         window.localStorage.setItem('being_keys', token)
//       })
//     })
//   }
//
// setTimeout(function() {getReCaptcha()}, 2000)
// setInterval(function() {getReCaptcha()}, 1000)

function SEO({ description, lang, meta, title, pageScript }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const meta2 = metaDescription.substring(-1, 152).concat('...')

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
        'href': "https://cdn.snipcart.com/themes/2.0/base/snipcart.min.css" ,
        'type': "text/css" ,
        'rel': "stylesheet"
        }
      ]}
      script={[
        {
          'src': 'https://www.rolex.com/corners/rlx-corner.js',
          "type": "text/javascript"

        },
        {
          'src': '//benchmarkshowcase.shopfinejewelry.com/v1/injectShowcase?key=34173cb38f07f89ddbebc2ac9128303f<https://urldefense.proofpoint.com/v2/url?u=http-3A__benchmarkshowcase.shopfinejewelry.com_v1_injectShowcase-3Fkey-3D34173cb38f07f89ddbebc2ac9128303f&d=DwMFaQ&c=euGZstcaTDllvimEN8b7jXrwqOf-v5A_CdpgnVfiiMM&r=joFEBHeCZ42EsWTaYXCp6LjGfuu9au7qNRvKwu2pHl8&m=TazgyvLxNrb4o9aQ6SSGi52_nz9hiWmhI7yQ2iXe_DA&s=0GxiM12zWxmSrF79bJhuGzKPLAif9UblAWTKZWvVDJg&e=>',
          'type': 'text/javascript'
        },
        {
          'src': "https://static.rolex.com/retailers/clock/retailercall.js",
          'type': 'text/javascript'
        },
        {
          'src': "https://ajax.googleapis.com/ajax/libs/jquery/2.2.2/jquery.min.js",
          'type': 'text/javascript'
        },
        {
          'src': 'https://static.rolex.com/retailers/clock/retailercall.js'
        }
      ]}
      meta={[
        {
          name: `description`,
          content: meta2,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "google-site-verification",
          content: "uUJvTH4_x4zzLrxRgLl3e8D5UYu7ZYqGdd4TqEjz6BA"
        }
      ].concat(meta)}
    >
    <script src="https://code.jquery.com/jquery-3.4.1.min.js" integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=" crossorigin="anonymous"></script>
    <script src="https://cdn.snipcart.com/scripts/2.0/snipcart.js" id="snipcart" data-api-key="YTJmNzQxYmUtZTc5MS00Zjk1LTlhNjctZjIxZGM0ZjZlZWMzNjM3MDM4MTU1OTQzOTAzODQ0"></script>
    <script>{`RLX = { legalURL: "https://www.underwoodjewelers.com/privacy-policy" }`}</script>
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-5936237-1"></script>
    <script>
       {`window.dataLayer = window.dataLayer || []
       function gtag(){dataLayer.push(arguments)}
       gtag('js', new Date())
       gtag('config', 'UA-5936237-1')`}
    </script>
    <script src="https://www.rolex.com/corners/rlx-corner.js"></script>
    <script src="https://www.google.com/recaptcha/api.js?render=6LcQhMMUAAAAAN8mNHD4PWVgslWkFw_fFUht0cjo"></script>
    <script>
      {`
      function getReCaptcha() {
          grecaptcha.ready(function() {
          grecaptcha.execute('6LcQhMMUAAAAAN8mNHD4PWVgslWkFw_fFUht0cjo', {action: 'homepage'}).then(function(token) {
              window.localStorage.setItem('being_keys', token)
            })
          })
        }

      setTimeout(function() { getReCaptcha() }, 2000)
      setInterval(function() { getReCaptcha() }, 10000)
      `}
    </script>
    <noscript>{`Discover more on <a href="https://www.rolex.com/watches.html">the Rolex watches</a>`}</noscript>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
