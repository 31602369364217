import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { colors, md } from '../common'

export default function Template({ data }) {
  const designer = data.allDesignersJson.edges[0].node
  return (
    <Layout>
      <SEO title={designer.name} />
      <div css={`padding:2rem;text-align:center;${md}{padding:2rem;}`}>
        <img css={`max-width:400px;width:100%;`} src={designer.logo} alt={`logo for ${designer.name}`} />
        <div dangerouslySetInnerHTML={{__html: designer.description}} css={`color:${colors.greyDarker};padding:2rem;line-height:24px;${md}{padding:1rem;}`} />
        <img css={`max-width:800px;width:100%;`} src={designer.featured_image} alt={designer.name} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($name: String!) {
    allDesignersJson(filter: {name: { eq: $name }}) {
      edges {
        node {
          slug
          name
          logo
          description
          featured_image
        }
      }
    }
  }
`
